<template>
  <modal-wrapper
    :id="id"
    size="md"
  >
    <template #title>
      Conferma di ricerca
    </template>
    <template #body>
      <p>
        <strong>Attenzione:</strong> ogni ricerca prevede un addebito pari a {{productCost}}, indipendemente dal risultato della ricerca.
        <br>
        Se si ha a disposizione delle ricerche prepagate, verrà scalato dal totale delle ricerche e non dal wallet
      </p>
      <p>Premere continua per procedere con l'operazione.</p>
      <p class="mt-3 mb-0">
        <strong>Dettaglio della ricerca:</strong>
      </p>
      <p v-if="hasName"><strong>Ragione sociale:</strong> {{searchData.name}}</p>
      <p v-if="hasTaxCode"><strong>P. Iva:</strong> {{searchData.taxCode}}</p>
    </template>
    <template #footer>
      <b-button
        variant="danger"
        size="sm"
        @click="closeModal"
        class="me-auto"
      >
        Annulla
      </b-button>
      <b-button variant="success" size="sm" @click="searchFunction">Continua</b-button>
    </template>
  </modal-wrapper>
</template>

<script>
import { isNotEmpty, isPresent } from '@/utils/validators';
import { formatDecimal } from '@/utils/formatterHelper';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ProfileSearchDataSearchModal',
  components: {
    ModalWrapper,
  },
  props: {
    id: String,
    searchFunction: Function,
    searchData: {
      name: String,
      taxCode: String,
    },
  },
  computed: {
    hasTaxCode() {
      // console.log('imma searchmodal');
      return isPresent(this.searchData.taxCode);
    },
    hasName() {
      return isPresent(this.searchData.name);
    },
    productCost() {
      const { price } = this.$store.getters['wallet/getPricing']('pr_company_other', 'pr_company_search');
      // console.log('price', price);
      return isNotEmpty(price) ? `${formatDecimal(price.amount, 'it')} ${price.currency}` : '';
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style scoped>

</style>
